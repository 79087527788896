import React from "react";
import Week from "./Week";

const Month = (props) => {
  const getDaysInMonth = props.getDaysInMonth;
  const monthAndYear = props.monthAndYear;
  const highlightDays = props.highlightDays;
  const daysList = props.daysList;
  return (
    <div className="monthContainer">
      {getDaysInMonth(monthAndYear.month, monthAndYear.year).map(
        (week, index) => {
          return (
            <Week
              key={index}
              week={week}
              highlightDays={highlightDays}
              daysList={daysList}
              numberOfWeeks={
                getDaysInMonth(monthAndYear.month, monthAndYear.year).length - 1
              }
            />
          );
        }
      )}
    </div>
  );
};

export default Month;
