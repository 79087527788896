import React from "react";

import MonthInput from "./MonthInput";
import YearInput from "./YearInput";
import CongesInput from "./CongesInput";

const Header = (props) => {
  const monthsList = props.monthsList;
  const monthAndYear = props.monthAndYear;
  const selectOptionsMonths = props.selectOptionsMonths;
  const selectOptionsYears = props.selectOptionsYears;
  const changeYear = props.changeYear;
  const congeDate = props.congeDate;
  const changeCongeDate = props.changeCongeDate;
  const changeMonth = props.changeMonth;
  return (
    <header>
      <div className="inputMonthAndYear">
        <MonthInput
          changeMonth={changeMonth}
          monthsList={monthsList}
          monthAndYear={monthAndYear}
          selectOptionsMonths={selectOptionsMonths}
        />
        <YearInput
          changeYear={changeYear}
          monthAndYear={monthAndYear}
          selectOptionsYears={selectOptionsYears}
        />
      </div>
      <CongesInput congeDate={congeDate} changeCongeDate={changeCongeDate} />
    </header>
  );
};

export default Header;
