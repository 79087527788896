import React, { useState, useEffect } from "react";
import "./App.css";

import Month from "./components/Month";
import Header from "./components/Header";
import { CgArrowLeftR, CgArrowRightR } from "react-icons/cg";
import axios from "axios";

function App() {
  const todaysDate = new Date();
  const [monthAndYear, setMonthAndYear] = useState({
    month: todaysDate.getMonth(),
    year: todaysDate.getFullYear(),
  });

  const [congeDate, setCongeDate] = useState(
    localStorage.getItem("conge") ? localStorage.getItem("conge") : ""
  );

  const [congesList, setCongesList] = useState([]);
  const [joursFeries, setJoursFeries] = useState([]);

  useEffect(() => {
    const calculJoursFeries = async () => {
      await axios
        .get(
          `https://calendrier.api.gouv.fr/jours-feries/metropole/${monthAndYear.year.toString()}.json`
        )
        .then((res) => {
          const newJoursFeries = [];
          Object.entries(res.data).forEach((date) => {
            newJoursFeries.push(new Date(date[0]));
          });
          setJoursFeries(newJoursFeries);
        });
    };
    calculJoursFeries();
  }, [monthAndYear.year]);

  useEffect(() => {
    let newDate = new Date(congeDate);
    let newCongesList = [];
    let addDate = null;
    for (let index = 0; index < 208; index++) {
      if (index === 0) {
        addDate = new Date(newDate.getTime());
        newCongesList.push(addDate);
      } else {
        if (newDate.getDay() === 5) {
          newDate.setDate(newDate.getDate() + 3);
        } else if (newDate.getDay() === 6) {
          newDate.setDate(newDate.getDate() + 9);
        } else {
          newDate.setDate(newDate.getDate() + 8);
        }
        addDate = new Date(newDate.getTime());
        newCongesList.push(addDate);
      }
    }
    newCongesList.forEach((conge, index) => {});
    setCongesList(newCongesList);
  }, [congeDate]);

  const monthsList = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];

  const daysList = [
    "Dimanche",
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
    "...",
  ];

  const getDaysInMonth = (month, year) => {
    const date = new Date(year, month, 1);
    const days = [];
    while (date.getMonth() === month) {
      const dayDate = new Date(date);
      const dateConverted = {
        number: dayDate.getDate(),
        day: dayDate.getDay(),
        month: dayDate.getMonth(),
        year: dayDate.getFullYear(),
        conge: false,
      };
      days.push(dateConverted);
      date.setDate(date.getDate() + 1);
    }
    let week = 0;
    const daysByWeek = [];
    days.forEach((day) => {
      if (day.day === 1 || days.indexOf(day) === 0) {
        week++;
        daysByWeek[week] = [];
      }
      if (days.indexOf(day) === 0 && day.day !== 1) {
        const falseDaysNumber = day.day === 0 ? 6 : day.day - 1;
        for (let index = 0; index < falseDaysNumber; index++) {
          const fakeDay = {
            number: null,
            day: 7,
            month: null,
            year: null,
            conge: false,
          };
          daysByWeek[week].push(fakeDay);
        }
      }
      daysByWeek[week].push(day);
    });
    const monthLength = daysByWeek.length - 1;
    const lastWeekLength = daysByWeek[monthLength].length - 1;
    if (daysByWeek[monthLength][lastWeekLength].day !== 0) {
      const falseDaysNumber = 7 - daysByWeek[monthLength][lastWeekLength].day;
      for (let index = 0; index < falseDaysNumber; index++) {
        const fakeDay = {
          number: null,
          day: 7,
          month: null,
          year: null,
          conge: false,
        };
        daysByWeek[monthLength].push(fakeDay);
      }
    }
    return daysByWeek;
  };

  const highlightDays = (day) => {
    let className = "";
    if (
      day.month === todaysDate.getMonth() &&
      day.number === todaysDate.getDate() &&
      day.year === todaysDate.getFullYear()
    ) {
      className += "today ";
    }
    if (day.day === 0 || day.day === 6) {
      className += "weekend ";
    }
    congesList.forEach((conge) => {
      if (
        day.month === conge.getMonth() &&
        day.number === conge.getDate() &&
        day.year === conge.getFullYear()
      ) {
        className += "conge ";
      }
    });
    joursFeries.forEach((jour) => {
      if (
        day.month === jour.getMonth() &&
        day.number === jour.getDate() &&
        day.year === jour.getFullYear()
      ) {
        className += "ferie ";
      }
    });
    return className;
  };

  const changeMonth = (e) => {
    const newDate = { ...monthAndYear };
    newDate.month = parseInt(e.target.value, 10);
    setMonthAndYear(newDate);
  };

  const changeYear = (e) => {
    const newDate = { ...monthAndYear };
    newDate.year = parseInt(e.target.value, 10);
    setMonthAndYear(newDate);
  };

  const selectOptionsMonths = () => {
    const numbers = [];
    for (let index = 0; index < 12; index++) {
      numbers.push(index);
    }
    return numbers;
  };

  const selectOptionsYears = () => {
    const numbers = [];
    for (let index = 2020; index < 2041; index++) {
      numbers.push(index);
    }
    return numbers;
  };

  const changeCongeDate = (e) => {
    localStorage.setItem("conge", e.target.value);
    setCongeDate(e.target.value);
  };

  const nextOrPrevMonth = (direction) => {
    const newDate = { ...monthAndYear };
    if (direction === "prev") {
      if (monthAndYear.month !== 0) {
        newDate.month--;
      } else {
        newDate.month = 11;
        newDate.year--;
      }
    } else if (direction === "next") {
      if (monthAndYear.month !== 11) {
        newDate.month++;
      } else {
        newDate.month = 0;
        newDate.year++;
      }
    }
    setMonthAndYear(newDate);
  };

  return (
    <div className="App">
      <Header
        monthsList={monthsList}
        monthAndYear={monthAndYear}
        selectOptionsMonths={selectOptionsMonths}
        selectOptionsYears={selectOptionsYears}
        changeYear={changeYear}
        nextOrPrevMonth={nextOrPrevMonth}
        changeMonth={changeMonth}
        congeDate={congeDate}
        changeCongeDate={changeCongeDate}
      />
      <div className="AppHeader">
        <CgArrowLeftR
          className="pointer arrows"
          onClick={() => nextOrPrevMonth("prev")}
        />
        <div className="monthAndYearContainer">
          <h1>{monthAndYear.year}</h1>
          <h1>{monthsList[monthAndYear.month]}</h1>
        </div>
        <CgArrowRightR
          className="pointer arrows"
          onClick={() => nextOrPrevMonth("next")}
        />
      </div>
      <Month
        getDaysInMonth={getDaysInMonth}
        monthAndYear={monthAndYear}
        highlightDays={highlightDays}
        daysList={daysList}
      />
    </div>
  );
}

export default App;
