import React from "react";

const Day = (props) => {
  const day = props.day;
  const highlightDays = props.highlightDays;
  const daysList = props.daysList;
  return (
    <div className={highlightDays(day) + "dayCard"}>
      <div className="dayContent day">{daysList[day.day]}</div>
      <div className="dayContent number">{day.number}</div>
    </div>
  );
};

export default Day;
