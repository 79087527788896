import React from "react";

const YearInput = (props) => {
  const changeYear = props.changeYear;
  const monthAndYear = props.monthAndYear;
  const selectOptionsYears = props.selectOptionsYears;
  return (
    <select className="select-css" name="years" onChange={(e) => changeYear(e)}>
      <option className="bg-grey" value="">
        {monthAndYear.year}
      </option>
      {selectOptionsYears().map((number, index) => {
        return (
          <option value={number} key={index}>
            {number}
          </option>
        );
      })}
    </select>
  );
};

export default YearInput;
