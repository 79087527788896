import React from "react";

const CongesInput = (props) => {
  const congeDate = props.congeDate;
  const changeCongeDate = props.changeCongeDate;
  return (
    <div className="congeInput">
      <div>
        <div className="labelConge">Date dernier congé :</div>
        <div>
          <input
            className="inputConge"
            type="date"
            value={congeDate}
            onChange={(e) => changeCongeDate(e)}
          ></input>
        </div>
      </div>
    </div>
  );
};

export default CongesInput;
