import React from "react";

const MonthInput = (props) => {
  const changeMonth = props.changeMonth;
  const monthsList = props.monthsList;
  const monthAndYear = props.monthAndYear;
  const selectOptionsMonths = props.selectOptionsMonths;

  return (
    <select
      className="select-css"
      name="months"
      onChange={(e) => changeMonth(e)}
    >
      <option className="bg-grey" value="">
        {monthsList[monthAndYear.month]}
      </option>
      {selectOptionsMonths().map((number, index) => {
        return (
          <option value={number} key={index}>
            {monthsList[number]}
          </option>
        );
      })}
    </select>
  );
};

export default MonthInput;
