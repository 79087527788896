import React from "react";
import Day from "./Day";

const Week = (props) => {
  const week = props.week;
  const highlightDays = props.highlightDays;
  const daysList = props.daysList;
  const numberOfWeeks = props.numberOfWeeks;
  const heightWeeks = (numberOfWeeks) => {
    if (numberOfWeeks === 4) {
      return "fourWeeks ";
    } else if (numberOfWeeks === 5) {
      return "fiveWeeks ";
    } else if (numberOfWeeks === 6) {
      return "sixWeeks ";
    }
  };
  return (
    <div className={heightWeeks(numberOfWeeks) + "weeksContainer"}>
      {week.map((day, index) => {
        return (
          <Day
            key={index}
            highlightDays={highlightDays}
            day={day}
            daysList={daysList}
          />
        );
      })}
    </div>
  );
};

export default Week;
